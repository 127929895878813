import React, { createRef, RefObject, useContext, useRef } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SearchParams } from "../typings/interfaces";
import NoResults from "./NoResults";
import { ifStyles, mapStyles, mergeStyles } from "../libs/styles";
import { ResponsiveTable } from "./ResponsiveTable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "../theme";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { AppContext } from "../contexts/AppContext";
import { useTranslation } from "react-i18next";
import GoogleImagesPopover from "./GoogleImagesPopover";
import { NaIcon } from "./Icons";
import { AspBrandsEnum } from "../typings/enums";
import { CartDataModel, OrderItemModel, ProductModel } from "../typings/models";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { applyMathFormula } from "../libs/mathFormula";

interface ProductListProps {
  searchParams?: Nullable<SearchParams>;
  items?: (ProductModel | OrderItemModel)[];
  hasCart?: boolean;
  cartMode?: boolean;
  viewMode?: boolean;
  cartData?: Nullable<CartDataModel>;
  isLoading?: boolean;
  displayItemsOnLoading?: boolean;
  displayNetPrice?: boolean;
  hideDiscountAndSubtotal?: boolean;
  sx?: Style;
}

export const ProductList: React.FC<ProductListProps> = (props) => {
  const {
    items,
    searchParams,
    hasCart,
    cartMode,
    viewMode,
    cartData,
    isLoading,
    displayItemsOnLoading,
    displayNetPrice,
    hideDiscountAndSubtotal,
    sx,
  } = props;
  const [ctx, updateCtx] = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const qtyInputRefs = useRef([]);
  qtyInputRefs.current = (items ?? []).map(
    (element, i) => qtyInputRefs.current[i] ?? createRef()
  );

  const addToCart = (product: ProductModel | OrderItemModel, qty?: number) => {
    console.log("addToCart", product, qty);
    updateCtx({
      cart: [
        ...(ctx.cart ?? []),
        {
          ...product,
          qty: +(qty ?? 1),
          computed: `#${ctx.cart.length + 1}. ${product.brand.toUpperCase()}: ${
            product.productId
          } x ${qty ?? 1} (${product.price}€) - ${product.name}`,
        },
      ],
    });
  };
  const removeFromCart = (index: number) => {
    console.log("removeFromCart", index);
    const newCart = JSON.parse(JSON.stringify(ctx.cart));
    newCart.splice(index, 1);
    updateCtx({
      cart: newCart,
    });
  };

  const changeQty = (index: number, qty: number = 0) => {
    const newCart = JSON.parse(JSON.stringify(ctx.cart));
    let newQty = +(newCart[index].qty ?? 0) + qty;
    if (newQty < 1) {
      newQty = 1;
    }
    newCart[index].qty = newQty;
    updateCtx({
      cart: newCart,
    });
  };

  const footerRows = (cartMode || viewMode) && cartData && (
    <>
      {!!cartData.discount &&
        ![0, "0", "-0", "-0%"].includes(cartData.discount) && (
          <>
            <TableRow>
              <TableCell colSpan={3} />
              {displayNetPrice && <TableCell />}
              <TableCell colSpan={3}>
                <Typography textAlign="right">
                  {t("components.ProductList.subTotal")}:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{cartData.subtotal}€</Typography>
              </TableCell>
              {displayNetPrice && (
                <TableCell>
                  <Typography sx={styles.netPriceSum}>
                    {cartData.netTotal}€
                  </Typography>
                </TableCell>
              )}
              {cartMode && <TableCell sx={styles.notPrintable} />}
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} />
              {displayNetPrice && <TableCell />}
              <TableCell colSpan={3}>
                <Typography textAlign="right">
                  {t("components.ProductList.discount")}:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{cartData.discount}</Typography>
              </TableCell>
              {displayNetPrice && (
                <TableCell>
                  <Typography sx={styles.netPriceSum}>0</Typography>
                </TableCell>
              )}
              {cartMode && <TableCell sx={styles.notPrintable} />}
            </TableRow>
          </>
        )}
      <TableRow>
        <TableCell colSpan={3} />
        {displayNetPrice && <TableCell />}
        <TableCell colSpan={3}>
          <Typography textAlign="right">
            {t("components.ProductList.total")}:
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{cartData.total}€</Typography>
        </TableCell>
        {displayNetPrice && (
          <TableCell>
            <Typography sx={styles.netPriceSum}>
              {cartData.netTotal}€
            </Typography>
          </TableCell>
        )}
        {cartMode && <TableCell sx={styles.notPrintable} />}
      </TableRow>
    </>
  );

  return (
    <Box sx={mergeStyles(styles.root, sx)}>
      {isLoading && <LinearProgress sx={styles.loading} />}

      {(!isLoading || displayItemsOnLoading) && items && items.length > 0 && (
        <ResponsiveTable
          sx={mergeStyles(
            styles.responsiveTableContainer,
            ifStyles(i18n.language === "ru", styles.longMobileLabels),
            ifStyles(displayItemsOnLoading && isLoading, styles.loadingTable)
          )}
        >
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {(cartMode || viewMode) && (
                    <>
                      <TableCell>
                        <Typography>#</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {t("components.ProductList.brand")}
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <Typography>
                      {t("components.ProductList.partNumber")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("components.ProductList.name")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t("components.ProductList.price")}</Typography>
                  </TableCell>
                  {displayNetPrice && (
                    <TableCell>
                      <Typography>
                        {t("components.ProductList.netPrice")}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell>
                    <Typography>{t("components.ProductList.qty")}</Typography>
                  </TableCell>
                  {(cartMode || viewMode) && (
                    <TableCell>
                      <Typography>{t("components.ProductList.sum")}</Typography>
                    </TableCell>
                  )}
                  {(cartMode || viewMode) && displayNetPrice && (
                    <TableCell>
                      <Typography>
                        {t("components.ProductList.netPriceSum")}
                      </Typography>
                    </TableCell>
                  )}
                  {!cartMode && hasCart && (
                    <TableCell>
                      <Typography>{t("components.ProductList.add")}</Typography>
                    </TableCell>
                  )}
                  {cartMode && (
                    <TableCell sx={styles.notPrintable}>
                      <Typography></Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((product, index) => {
                  let thisItemInCart = ctx.cart.find(
                    (i: ProductModel | OrderItemModel) =>
                      i.productId === product.productId
                  );
                  let brand = product.brand ?? searchParams?.brand;

                  return (
                    <TableRow key={index}>
                      {(cartMode || viewMode) && (
                        <>
                          <TableCell data-label="#">
                            <Typography sx={styles.index}>
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            data-label={t("components.ProductList.brand")}
                          >
                            <Typography sx={styles.brand}>
                              {product.brand?.toUpperCase()}
                            </Typography>
                          </TableCell>
                        </>
                      )}

                      <TableCell
                        data-label={t("components.ProductList.partNumber")}
                      >
                        <Stack direction="row" gap="12px" alignItems="center">
                          <GoogleImagesPopover
                            brand={brand}
                            partNumber={product.productId}
                            sx={mergeStyles(
                              styles.imagesButton,
                              styles.notPrintable
                            )}
                          />
                          {searchParams?.search !== product.productId &&
                          searchParams?.search.includes(product.productId) &&
                          searchParams?.search?.length !==
                            product.productId.length ? (
                            <Tooltip
                              title={t(
                                "components.ProductList.similarPartNumber"
                              )}
                              arrow={true}
                              placement="right"
                            >
                              <WarningOutlinedIcon color="warning" />
                            </Tooltip>
                          ) : null}
                          <Stack
                            direction="row"
                            gap="12px"
                            alignItems="center"
                            sx={styles.partNumberInnerWrapper}
                          >
                            <Typography
                              sx={mergeStyles(
                                styles.productId,
                                ifStyles(
                                  !!(product as ProductModel).newProductId,
                                  styles.oldPartNumber
                                )
                              )}
                            >
                              {product.productId}
                              {!cartMode &&
                                !viewMode &&
                                searchParams?.search !== product.productId &&
                                (searchParams?.search.length ===
                                  product.productId.length ||
                                  !searchParams?.search.includes(
                                    product.productId
                                  )) &&
                                !Object.values(AspBrandsEnum).includes(
                                  brand as AspBrandsEnum
                                ) && (
                                  <FiberNewIcon
                                    sx={styles.productReplacementIcon}
                                  />
                                )}
                            </Typography>
                            {!!(product as ProductModel).newProductId && (
                              <>
                                <ArrowForwardIcon
                                  sx={styles.partNumberReplacementArrow}
                                />
                                <Typography sx={styles.newProductId}>
                                  {(product as ProductModel).newProductId}
                                  <FiberNewIcon
                                    sx={styles.productReplacementIcon}
                                  />
                                </Typography>
                              </>
                            )}
                          </Stack>
                        </Stack>
                      </TableCell>

                      <TableCell data-label={t("components.ProductList.name")}>
                        <Typography sx={styles.name}>{product.name}</Typography>
                      </TableCell>

                      <TableCell data-label={t("components.ProductList.price")}>
                        {cartData?.discount?.includes("%") ? (
                          <>
                            <Typography sx={styles.oldPrice}>
                              {product.price}€
                            </Typography>
                            <Typography sx={styles.price}>
                              {applyMathFormula(
                                product.price,
                                cartData.discount
                              ).toFixed(2)}
                              €
                            </Typography>
                          </>
                        ) : (
                          <Typography sx={styles.price}>
                            {product.price}€
                          </Typography>
                        )}
                      </TableCell>

                      {displayNetPrice && (
                        <TableCell
                          data-label={t("components.ProductList.netPrice")}
                        >
                          <Typography sx={styles.netPrice}>
                            {product.netPrice}€
                          </Typography>
                        </TableCell>
                      )}

                      <TableCell data-label={t("components.ProductList.qty")}>
                        {viewMode ? (
                          (product as OrderItemModel).qty
                        ) : cartMode ? (
                          <Stack gap="6px" direction="row" alignItems="center">
                            <IconButton
                              onClick={() => changeQty(index, -1)}
                              disabled={isLoading}
                              sx={styles.notPrintable}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                            {(product as OrderItemModel).qty}
                            <IconButton
                              onClick={() => changeQty(index, 1)}
                              disabled={isLoading}
                              sx={styles.notPrintable}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Stack>
                        ) : (
                          <TextField
                            inputRef={qtyInputRefs.current[index]}
                            defaultValue={
                              !!thisItemInCart ? thisItemInCart.qty : 1
                            }
                            type="number"
                            sx={styles.qtyInput}
                            size="small"
                            disabled={
                              !!thisItemInCart ||
                              !!(product as ProductModel).newProductId
                            }
                            inputProps={{
                              min: 1,
                              max: 1000,
                              step: 1,
                            }}
                          />
                        )}
                      </TableCell>
                      {(cartMode || viewMode) && (
                        <TableCell data-label={t("components.ProductList.sum")}>
                          {cartData?.discount?.includes("%") ? (
                            <>
                              <Typography sx={styles.oldSum}>
                                {(
                                  ((product as OrderItemModel).qty ?? 0) *
                                  (product.price ?? 0)
                                ).toFixed(2)}
                                €
                              </Typography>
                              <Typography sx={styles.sum}>
                                {applyMathFormula(
                                  ((product as OrderItemModel).qty ?? 0) *
                                    (product.price ?? 0),
                                  cartData.discount
                                ).toFixed(2)}
                                €
                              </Typography>
                            </>
                          ) : (
                            <Typography sx={styles.sum}>
                              {(
                                ((product as OrderItemModel).qty ?? 0) *
                                (product.price ?? 0)
                              ).toFixed(2)}
                              €
                            </Typography>
                          )}
                        </TableCell>
                      )}
                      {(cartMode || viewMode) && displayNetPrice && (
                        <TableCell
                          data-label={t("components.ProductList.netPriceSum")}
                        >
                          <Typography sx={styles.netPriceSum}>
                            {(
                              ((product as OrderItemModel).qty ?? 0) *
                              (product.netPrice ?? 0)
                            ).toFixed(2)}
                            €
                          </Typography>
                        </TableCell>
                      )}
                      {!cartMode && hasCart && (
                        <TableCell data-label={t("components.ProductList.add")}>
                          {!(product as ProductModel).newProductId ? (
                            !!thisItemInCart ? (
                              <IconButton disabled>
                                <DoneIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  addToCart(
                                    product,
                                    (
                                      qtyInputRefs.current[
                                        index
                                      ] as RefObject<any>
                                    )?.current?.value
                                  )
                                }
                              >
                                <ShoppingCartIcon />
                              </IconButton>
                            )
                          ) : (
                            <IconButton disabled>
                              <NaIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                      {cartMode && (
                        <TableCell
                          data-label={t("components.ProductList.remove")}
                          sx={styles.notPrintable}
                        >
                          <IconButton onClick={() => removeFromCart(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              {(cartMode || viewMode) && (
                <TableFooter sx={mergeStyles(styles.tfoot)}>
                  {footerRows}
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </ResponsiveTable>
      )}
      {!!searchParams?.brand && !!searchParams?.search ? (
        <NoResults
          hide={!items || items?.length > 0 || isLoading}
          message={t("components.ProductList.notFound")}
          sx={styles.noResults}
        />
      ) : (
        <NoResults
          hide={
            cartMode || viewMode || !items || items?.length > 0 || isLoading
          }
          message={t("components.ProductList.searchSomething")}
          sx={styles.noResults}
        />
      )}
    </Box>
  );
};

const styles = mapStyles({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    overflow: "auto",
    minHeight: "36px",
    width: "100%",

    "& .MuiTableRow-root": {
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.palette.background.default,
      },
      "&:nth-of-type(odd)": {
        [theme.breakpoints.down("md")]: {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    "& .MuiTableHead-root .MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: 1,
    },
    "& .MuiTableCell-root": {
      [theme.breakpoints.up("md")]: {
        height: "50px",
      },
      py: 0.5,
      px: 1,
      "@media print": {
        p: "0.75em",
      },
    },
    "& .MuiTableFooter-root .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    "@media print": {},
  },
  loadingTable: {
    opacity: 0.5,
  },
  responsiveTableContainer: {
    overflow: "initial",
  },
  tableContainer: {
    overflow: "initial",
  },
  longMobileLabels: {
    "--labelWidth": "140px",
  },
  loading: {
    position: "absolute",
    top: 0,
    left: "16px",
    right: "16px",
  },
  index: {
    color: theme.palette.text.disabled,
  },
  brand: {},
  productId: {
    display: "flex",
  },
  oldPartNumber: {
    textDecoration: "line-through",
    opacity: "0.7",
  },
  partNumberReplacementArrow: {
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",
    },
  },
  partNumberInnerWrapper: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 0,
    },
  },
  newProductId: {
    display: "flex",
  },
  name: {},
  oldPrice: {
    textDecoration: "line-through",
    color: (ct) => ct.palette.grey["600"],
  },
  price: {},
  netPrice: {
    color: (ct) => ct.palette.grey["600"],
  },
  oldSum: {
    textDecoration: "line-through",
    color: (ct) => ct.palette.grey["600"],
  },
  sum: {},
  netPriceSum: {
    color: (ct) => ct.palette.grey["600"],
  },
  noResults: {
    margin: "12px 0",
    fontSize: "20px",
  },
  qtyInput: {
    width: "72px",
    textAlign: "center",
  },
  productReplacementIcon: {
    fontSize: "1em",
    marginLeft: "0.5em",
    position: "relative",
    top: "-0.25em",

    [theme.breakpoints.down("sm")]: {
      marginRight: "-1.5em",
    },
  },
  tfoot: {
    "@media print": {
      // breakInside: 'avoid-page',
    },
  },
  notPrintable: {
    "@media print": {
      display: "none",
    },
  },
  onlyPrintable: {
    "@media screen": {
      display: "none",
    },
  },
  imagesButton: {
    margin: -1,
  },
});

export default ProductList;
