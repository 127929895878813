import React, { useContext, useMemo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { mapStyles } from "../libs/styles";
import useOrders from "../hooks/useOrders";
import DateTime from "../components/DateTime";
import { Count } from "../typings/interfaces";
import Header from "../components/Header";
import { OrderModel } from "../typings/models";
import { useTranslation } from "react-i18next";
import { ClientTypeEnum } from "../typings/enums";
import { AppContext } from "../contexts/AppContext";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import { ADMIN_URL, API_URL } from "../constants/common";

export const OrderListPage: React.FC = (props) => {
  const [ctx, updateCtx] = useContext(AppContext);

  const { items } = useOrders<OrderModel<Count>>({
    populateOrdersAsCount: true,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenOrder = (id: number) => {
    navigate(`/orders/${id}`);
  };

  const isMotoparts = useMemo(
    () => ctx?.user?.clientType === ClientTypeEnum.Motoparts,
    [ctx]
  );

  return (
    <Box sx={styles.root}>
      <Header
        breadcrumbs={
          <Breadcrumbs>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              {t("components.Breadcrumbs.search")}
            </Link>
            <Typography color="text.primary">
              {t("components.Breadcrumbs.orders")}
            </Typography>
          </Breadcrumbs>
        }
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t("pages.OrderListPage.createdAt")}</TableCell>
              <TableCell>{t("pages.OrderListPage.orderedItems")}</TableCell>
              <TableCell>{t("pages.OrderListPage.total")}</TableCell>
              <TableCell>{t("pages.OrderListPage.status")}</TableCell>
              {isMotoparts && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => (
              <TableRow
                key={idx}
                hover
                onClick={() => handleOpenOrder(item.id)}
                sx={styles.bodyRow}
              >
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  <DateTime value={item.createdAt} />
                </TableCell>
                <TableCell>
                  <Typography>{item.order.count}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{item.total}€</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {t(`enums.OrderStatusEnum.${item.status}`)}
                  </Typography>
                </TableCell>
                {isMotoparts && (
                  <TableCell>
                    <Tooltip
                      arrow
                      placement="left"
                      title={t("pages.OrderListPage.createInvoice")}
                    >
                      <IconButton
                        href={`${ADMIN_URL}/plugins/invoice?order=${item.id}`}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <RequestPageOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxHeight: "var(--appHeight, 100vh)",
    overflow: "hidden",
    width: "100%",
  },
  bodyRow: {
    cursor: "pointer",
  },
});

export default OrderListPage;
