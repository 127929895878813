export function applyMathFormula(value: number, formula: string): number {
  // console.log("mdPriceCalculation", value, formula);

  const regex = /((?:[-+*/])*([0-9.,]+)(?:[%])*)/gm;
  const formulaSteps = [...formula.replace(/ /g, "").matchAll(regex)]
    .map((i) => i[0])
    .filter((i) => !!i);
  return formulaSteps.reduce((acc: number, step: string) => {
    const cleanValue: string = step.match(/([0-9.,]+)/)?.[0] ?? "0";
    const value: number = step.includes("%")
      ? acc * (+cleanValue * 0.01)
      : +cleanValue;
    const negativity = step.includes("-") ? -1 : 1;
    if (step.includes("*")) {
      acc = acc * (negativity * value);
      // console.log('multiple', step, value, negativity, acc);
    } else if (step.includes("/")) {
      acc = acc / (negativity * value);
      // console.log('divide', step, value, negativity, acc);
    } else {
      acc = acc + negativity * value;
      // console.log('sum', step, value, negativity, acc);
    }
    return acc;
  }, value);
}
